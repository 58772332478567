<template>
  <v-card>
    <v-card-title>
      Tissue Information
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="getDaskboard"
      fixed-header
      height="300"
    >
      <template #[`item.offer_date`]="{ item }" style="display:flex">
        {{offerDate(item.offer_date)}}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Tissue ID', value: 'tissue_no' },
        { text: 'Surgeon Name', value: 'bill_to' },
        { text: 'Tissue Category', value: 'tissue_category' },
        { text: 'Amount', value: 'reimbursement_amount' },
        { text: 'Offered Date', value: 'offer_date' },
  
      ],
      getDaskboard:[],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,

      
    }
  },
  watch: {
    options: {
      handler() {
        this.ViewDaskBoard()
      },
      deep: true,
    },
  },
  async mounted() {
    this.ViewDaskBoard()
  },
  methods:{

    offerDate(data){
      return (moment(data).format("DD-MM-YYYY"))
    },

    async ViewDaskBoard(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
       if (response) {
        this.getDaskboard = response.tissue_information
        this.totallist = this.getDaskboard.length
        this.offerDate()
      } else {
        this.getDaskboard = []
        this.totallist = 0
      }
    },
  }
}
</script>
